import React from "react";

import { FaTwitter } from "react-icons/fa";
import { BsTelegram } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";
import "./footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="d-flex gap-5 align-items-center footer-desc">
        <h5>SpaceShipEarth</h5>
        <p>SpaceShipEarth - Where fun meets growth.</p>
      </div>
      <div className="mt-2 mb-2">
        <div className="d-flex gap-3  navLink">
          {/* <p onClick={() => window.open("/#airdrop", "_self")}>Airdrop</p>
          <p onClick={() => window.open("/#about_us", "_self")}>About Us</p>
          <p onClick={() => window.open("/#token_economics", "_self")}>
            Token Economics
          </p> */}
        </div>
        <div className="d-flex gap-3 mt-3">
          <div
            className="icon"
            onClick={() =>
              window.open(
                "https://t.me/+MSdvTr-RO8FiN2U0",
                "_blank"
              )
            }
          >
            <BsTelegram />
          </div>
          <div
            className="icon"
            onClick={() =>
              window.open("https://twitter.com/SpaceshipearthT", "_blank")
            }
          >
            <FaTwitter />
          </div>
          <div
            className="icon"
            onClick={() =>
              window.open("https://discord.gg/mcSK92d6", "_blank")
            }
          >
            <FaDiscord />
          </div>
        </div>
      </div>
    </div>
  );
}
