import { createClient } from "wagmi";
import { arbitrumGoerli, arbitrum, bsc, bscTestnet } from "wagmi/chains";

import { IS_DEVELOPMENT, ALCHEMY_KEY } from "../../constant";
import { getDefaultClient } from "connectkit";

const getTestChains = () => (IS_DEVELOPMENT ? [bscTestnet] : [bsc]);

const chains = getTestChains();

export const client = createClient(
  getDefaultClient({
    chains,
    appName: "SpaceDoge",
    alchemyId: ALCHEMY_KEY,
  })
);
