import React, { useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { BounceLoader } from "react-spinners";
import ProgressBar from "@ramonak/react-progress-bar";

import { useClaim } from "../../services/contracts/integration";

import "./hero.css";

export default function Hero() {
  const { address } = useAccount();
  const [refcopy, setRefcopy] = useState(false);
  const { handleClaim, isClaiming } = useClaim();
  console.log("address", address);
  const referralAddress = useMemo(
    () => `${window.origin}?ref=${address}`,
    [address]
  );

  return (
    <div id="airdrop" className="hero py-5">
      <div className=" pb-2">
        <h2>The token that’s all about</h2>
      </div>
      <div className="mb-3">
        <h2 className="space">SpaceShipEarth</h2>
        {/* <p>
          It shoulders the important task of revitalizing ArbitrumChain MEME
          Coin.
        </p> */}
      </div>
      <div className="hexagonal">
        <div className="inner">
          <p style={{ fontSize: "25px" }}>
          Get your  <span style={{ color: "#12EC77" }}>Spaceshipearth tokens now</span>
          </p>
          <p className="mt-2 mb-2" style={{ fontSize: "15px", color: "#fff" }}>
          Airdrop Alert! We are airdropping a large amount of{" "}
            <span style={{ color: "#12EC77" }}>$SpaceShipEarth </span>
            tokens to our community. Claim your share now. Share will be based on the holders, tasks completed and evidences received. Thank you community.
          </p>
          {/* <button className="burned">14 days will be burned</button> */}
          <div className="claim">
            <p>Claimed</p>
            <p>130,000,000,000,000</p>
          </div>

          <ProgressBar
            completed={10}
            bgColor={"#31ff6f"}
            baseBgColor={"#183d2f"}
            isLabelVisible={false}
          />
          <button
            className="claimButton"
            onClick={handleClaim}
            style={{ display: "flex", alignItems: "center" }}
          >
            {isClaiming && (
              <span style={{ marginRight: 5 }}>
                <BounceLoader size={25} />
              </span>
            )}
            Claim Now
          </button>

          {/* <p
            className="mt-2 mb-2"
            style={{ fontSize: "15px", color: "#73A095" }}
          >
            Share your referral link and get paid 5% instantly to your wallet
            for every referred token purchase.
          </p> */}
        </div>
      </div>
    </div>
  );
}
