import { WagmiConfig } from "wagmi";
import { ConnectKitProvider } from "connectkit";
import { BrowserRouter } from "react-router-dom";

import { client } from "./services/walletIntegration";
import Navbar from "./components/Navbar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Hero from "./components/Hero/Hero";
import Sidoge from "./components/Sidoge/Sidoge";
import Token from "./components/Token/Token";
import Footer from "./components/Footer/Footer";

import "./App.css";

const DARK_COLOR = "#023025";
const BUTTON_BACKGROUND = "#7eff81";
const BUTTON_COLOR = "#232323";

const walletModalTheme = {
  " --ck-font-family": "Lora, serif",
  "--ck-overlay-backdrop-filter": DARK_COLOR,
  "--ck-body-background": DARK_COLOR,
  "--ck-tooltip-background": DARK_COLOR,
  "--ck-body-background-transparent": DARK_COLOR,
  "--ck-body-action-color": BUTTON_BACKGROUND,
  "--ck-body-background-secondary": DARK_COLOR,
  "--ck-connectbutton-color": DARK_COLOR,
  "--ck-connectbutton-hover-color": BUTTON_COLOR,
  "--ck-connectbutton-active-color": BUTTON_COLOR,
  "--ck-connectbutton-background": BUTTON_BACKGROUND,
  "--ck-connectbutton-hover-background": BUTTON_BACKGROUND,
  "--ck-connectbutton-active-background": BUTTON_BACKGROUND,
  "--ck-dropdown-button-color": DARK_COLOR,
  "--ck-dropdown-button-hover-color": DARK_COLOR,
  "--ck-dropdown-button-background": BUTTON_BACKGROUND,
  "--ck-dropdown-button-hover-background": BUTTON_BACKGROUND,
  "--ck-primary-button-color": DARK_COLOR,
  "--ck-primary-button-hover-color": DARK_COLOR,
  "--ck-primary-button-background": BUTTON_BACKGROUND,
  "--ck-primary-button-hover-background": BUTTON_BACKGROUND,
  "--ck-secondary-button-color": DARK_COLOR,
  "--ck-secondary-button-hover-color": DARK_COLOR,
  "--ck-secondary-button-background": BUTTON_BACKGROUND,
  "--ck-secondary-button-hover-background": BUTTON_BACKGROUND,
  "--ck-body-color-muted": BUTTON_BACKGROUND,
  "--ck-body-color-muted-hover": BUTTON_BACKGROUND,
  "--ck-body-color": BUTTON_BACKGROUND,
};

function App() {
  return (
    <BrowserRouter>
      <WagmiConfig client={client}>
        <ConnectKitProvider customTheme={walletModalTheme}>
          <div className="App">
            <Navbar />
            <Hero />
            {/* <Sidoge />
            <Token /> */}
            <Footer />
          </div>
        </ConnectKitProvider>
      </WagmiConfig>
    </BrowserRouter>
  );
}

export default App;
