import abi from "../services/contracts/abi/nftContract.json";

export const IS_DEVELOPMENT = false;
export const ALCHEMY_KEY = "NxCvc404dOiCQCNcKlySRp1_gKV6VC1-";

export const CONTRACT_INFO = {
  address: "0xd8246946cF19d1AC5A24C2f749CB6D454bbfb7D3",
  abi,
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
