import {
  useAccount,
  useContract,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";

import { CONTRACT_INFO, ZERO_ADDRESS } from "../../../constant";
import { useQueryParams } from "../../hook/useQueryParams";
import Web3 from "web3";
export const useSpaceContract = () => {
  return useContract(CONTRACT_INFO);
};

export const useCost = () => {
  const { data, isLoading } = useContractRead({
    ...CONTRACT_INFO,
    functionName: "cost",
  });

  return { cost: data?.toString(), isLoadingCost: isLoading };
};

export const useClaim = () => {
  const web3 = new Web3(Web3.givenProvider);
  // get the contract
  const contract = new web3.eth.Contract(
    CONTRACT_INFO.abi,
    CONTRACT_INFO.address
  );

  // get the current metamask account

  const { isConnected } = useAccount();
  const { cost, isLoadingCost } = useCost();
  const referral = useQueryParams();

  // const { config } = usePrepareContractWrite({
  //   ...CONTRACT_INFO,
  //   functionName: "claimAirdrop",
  //   args: [referral ?? ZERO_ADDRESS],
  //   overrides: {
  //     gasLimit: 285000,
  //     value: cost,
  //   },
  // });

  //const { isLoading, write } = useContractWrite(config);

  const handleClaim = async () => {
    if (isConnected && !isLoadingCost) {
      const _account = await web3.eth.getAccounts();
      console.log("cost", cost);
      await contract.methods
        .claimAirdrop()
        .send({ from: _account[0], value: cost })
        .on("transactionHash", function (hash) {
          console.log("transactionHash", hash);
        })
        .on("receipt", function (receipt) {
          console.log("receipt", receipt);
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          console.log("confirmation", confirmationNumber, receipt);
        })
        .on("error", function (error, receipt) {
          console.log("error", error, receipt);
        });
    }
  };

  return {
    handleClaim,
    isClaiming: "",
  };
};
