import React from "react";
import { SiBinance } from "react-icons/si";
import { FaTwitter } from "react-icons/fa";
import { BsTelegram } from "react-icons/bs";

import { FaDiscord } from "react-icons/fa";
import "./navbar.css";
import Logo from "../../img/logo.png";
import { ConnectKitButton } from "connectkit";

export default function Navbar() {
  return (
    <div className=" mynav">
      <div className="logo">
        <a href="https://spaceshipearthtoken.com/">
          <img src={Logo} alt="" width="50px" height="50px" />
        </a>
        <h5>SpaceShipEarth</h5>
      </div>
      <div className="navLink">
        <p onClick={() => window.open("/#airdrop", "_self")}>Airdrop</p>
      </div>
      <div className="d-flex gap-2 ">
        <div
          className="icon"
          onClick={() =>
            window.open("https://t.me/+MSdvTr-RO8FiN2U0", "_blank")
          }
        >
          <BsTelegram />
        </div>
        <div
          className="icon"
          onClick={() =>
            window.open("https://twitter.com/SpaceshipearthT", "_blank")
          }
        >
          <FaTwitter />
        </div>

        <div
          className="icon"
          onClick={() =>
            window.open("https://discord.gg/mcSK92d6", "_blank")
          }
        >
          <FaDiscord />
        </div>

        <ConnectKitButton style={{ margin: 10 }} />
      </div>
    </div>
  );
}
